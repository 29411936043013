//React
import React from 'react'
import { connect } from 'react-redux'

//Packages
import Helmet from 'react-helmet'
// import Recaptcha from 'react-recaptcha'
import parse from 'url-parse'

//Actions
import { saveUnsub } from '../actions/saveUnsubscribe'

//Components
import Layout from '../components/layout'

//Icons
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({ unsubsubmitted, isLoading }) => {
  return { unsubsubmitted, isLoading }
}
const mapDispatchToProps = (dispatch) => ({
  saveUnsub: (email) => {
    dispatch(saveUnsub(email))
  },
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { recaptchaVerified: false, errorText: '' }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  // specifying your onload callback function
  // onLoadCallback = () => {
  //   console.log('Done!!!!')
  // }
  // verifyCallback = response => {
  //   this.setState({ recaptchaVerified: true })
  //   console.log(response)
  // }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const url = parse(window.location.href, true)
      if (url.query.i) {
        console.log('url.query.i')
        console.log(url.query.i)
        this.sendUnsub(url.query.i)
      }
    }
  }

  sendUnsub(email) {
    this.props.saveUnsub(email)
  }

  handleUpdate = (event) => {
    if (event.target.type === 'checkbox') {
      this.setState({
        [event.target.name]: event.target.checked,
      })
    } else {
      if (event.target.name === 'unsubEmail') {
        this.setState({
          [event.target.name]: encodeURIComponent(event.target.value),
        })
      } else {
        this.setState({
          [event.target.name]: event.target.value,
        })
      }
    }
  }

  handleSubmit() {
    if (!this.state.unsubEmail) {
      this.setState({ errorText: 'Please enter your e-mail address' })
      return
    } else {
      this.sendUnsub(this.state.unsubEmail)
    }
  }

  render() {
    const { isLoading, unsubsubmitted } = this.props
    let url
    if (typeof window !== `undefined`) {
      url = parse(window.location.href, true)
    }
    return (
      <Layout>
        {/* <Helmet>
          <script
            src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
            async
            defer
          ></script>
        </Helmet> */}
        <Helmet title="Picohealth - Unsubscribe" />
        <div class="container container-unsub flex-grow-1">
          <div class="row">
            <div class="col-lg-6">
              <h2>Unsubscribe</h2>

              {unsubsubmitted ? (
                <p>
                  We've unsubscribed your email{' '}
                  <i>
                    {url.query.i
                      ? decodeURIComponent(url.query.i)
                      : decodeURIComponent(this.state.unsubEmail)}
                  </i>{' '}
                  from further communications
                </p>
              ) : (
                <div>
                  <p>Please enter your email below to unsubscribe</p>
                  <div class="form-group p-0">
                    <div class="w-100">
                      <input
                        class={`form-control my-2 ${
                          this.state.errorText ? 'is-invalid' : ''
                        }`}
                        id="unsubEmail"
                        name="unsubEmail"
                        placeholder="E-mail"
                        onChange={this.handleUpdate}
                      />
                      <div class="invalid-feedback mb-3">
                        {this.state.errorText}
                      </div>
                    </div>
                    {/* <Recaptcha
                  sitekey={process.env.RECAPTCHA_SITE_KEY}
                  render="explicit"
                  verifyCallback={this.verifyCallback}
                  onloadCallback={this.onLoadCallback}
                /> */}

                    <button
                      // disabled={
                      //   this.state.recaptchaVerified === true ? false : true
                      // }
                      type="submit"
                      class={`btn btn-primary btn-block my-2 `}
                      onClick={() => {
                        this.handleSubmit()
                      }}
                    >
                      Submit
                      {isLoading ? (
                        <FaSpinner
                          size={15}
                          className="icon-spin"
                          style={{ marginLeft: '10px' }}
                        />
                      ) : null}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
